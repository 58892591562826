import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect
} from "react";
import logo from "./logo.png";
import * as CryptoJS from "crypto-js";
import { saveAs } from "file-saver";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

import DownloadModal from "./components/DownloadModal";
import ShowPassModal from "./components/ShowPassModal"

import { downloadFile } from "./lib/downloadLib";
import { uploadFile } from "./lib/uploadLib";
import getOperatingSistem from "./lib/operatingSistem";
import fbConfig from "./conf/firebaseConfig";
import { readLocalStorageFiles, saveLocalStorage } from "./lib/localStorageLib";

import "./Button.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCog,
  faDownload,
  faTrash,
  faLock,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Alert from "react-bootstrap/Alert";
import Navbar from "react-bootstrap/Navbar";
import ListGroup from "react-bootstrap/ListGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import { GeoFirestore } from "geofirestore";

firebase.initializeApp(fbConfig);

const firestore = firebase.firestore();
const storageRef = firebase.storage().ref();
const geofirestore = new GeoFirestore(firestore);
const geocollection = geofirestore.collection("files");

function App() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [radius] = useState(0.04);
  const [rememberPass, setRememberPass] = useState("");
  const [nearFiles, setNearFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  //const [showDisplayPassModal, setShowDisplayPassModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [searchRadius, setRadius] = useState(50);
  const [ownFiles, setOwnFiles] = useState([]);
  const [addingFile, setAddingFile] = useState(false);
  const [location, setLocation] = useState(null);
  const [alertInfo, setAlertInfo] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [publicFileName, setPublicFileName] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [filePassword, setFilePassword] = useState("");
  const [fileSelected, setFileSelected] = useState("Choose file");
  const [dateNow, setDateNow] = useState("");
  const [privateFile] = useState(false);
  const [compresedFile, setCompresedFile] = useState(null);
  const [downloadPassword, setDownloadPassword] = useState("");
  const [downloadHash, setDownloadHash] = useState("");
  const [downloadType, setDownloadType] = useState("");
  const [downloadLik, setDownloadLink] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseSettins = () => setShowSettingsModal(false);
  const handleShowSetings = () => setShowSettingsModal(true);
  const handleClosePassModal = () => setShowPassModal(false);
  const handleShowPassModal = () => setShowPassModal(true);
  const handleCloseDownloadModal = () => setShowDownloadModal(false);
  const handleShowDownloadModal = () => setShowDownloadModal(true);
  const handleCloseDisplayPassModal = () => {
    setRememberPass("");
    setShowPassModal(false);
  };
  const handleShowDisplayPassModal = pass => {
    setRememberPass(pass);
    setShowPassModal(true);
  };

  const resetDownloadForm = () => {
    setDownloadPassword("");
    setDownloadHash("");
  };

  const resetUploadForm = () => {
    console.log("reset Form");
    setAuthorName("");
    setDateNow("");
    setFileSelected("Choose file");
    setFilePassword("");
    setFileName("");
    setPublicFileName("");
  };

  const handleUploadFile = e => {
    handleCloseModal();
    uploadFile(
      storageRef,
      firebase,
      fileName,
      compresedFile,
      uploadSucess,
      uploadProgress,
      uploadError
    );
  };

  const uploadSucess = downloadURL => {
    addFile();
    setAlertInfo({
      variant: "success",
      message: "File uploaded correctly.",
      autoHide: true
    });
  };
  const uploadProgress = message => {
    setAlertInfo(message);
  };
  const uploadError = message => {
    setAlertInfo(message);
  };

  const downloadSucess = (url, type) => {
    //setShowDownloadModal(true);
    if (getOperatingSistem() === "OSX") {
      setDownloadLink(url);
      handleShowDownloadModal();
    } else {
      saveAs(url);
    }
    resetDownloadForm();
  };

  const handleSelectFile = e => {
    if (e.target.files[0]) {
      const now = Date.now();
      const theFileName = e.target.files[0].name;
      const theFileType = e.target.files[0].type;
      const theFileExtension = e.target.files[0].name.split(".").pop();
      setDateNow(now);
      setFileSelected(theFileName);
      setFileType(theFileType);
      setFileName(
        `${CryptoJS.MD5(`${theFileName}${now}`)}.${theFileExtension}`
      );
      setCompresedFile(e.target.files[0]);
    }
  };

  const deleteFile = (element) => {
    console.log("delete ", element.id);
    geocollection.doc(element.id).delete().then((result)=>{
      console.log(result, "deleted")
    }).catch((error)=>{
      console.error("Error removing document: ", error);
  })
  }

  const addFile = () => {
    const publicFile = filePassword === "" ? true : false;
    geocollection
      .add({
        authorName,
        publicFileName,
        privateFile,
        type: fileType,
        date: dateNow,
        p: publicFile,
        hash: `${CryptoJS.AES.encrypt(fileName, filePassword)}`,
        coordinates: location
      })
      .then(docRef => {
        let files = ownFiles;
        files.push({ id: docRef.id, password: filePassword });
        setAddingFile(true);
        setOwnFiles(files);
        resetUploadForm();
      });
  };

  const geo_success = position => {
    const currentPosition = new firebase.firestore.GeoPoint(
      position.coords.latitude,
      position.coords.longitude
    );
    setLocation(currentPosition);
    const query = geocollection.near({
      center: currentPosition,
      radius
    });
    query.onSnapshot(snapshot => {
      setNearFiles(snapshot.docs);
    });
  };

  const listFiles = () => {
    if (nearFiles) {
      return nearFiles.map((el, idx) => {
        const elementPass = ownFiles.find(
          obj => obj.password !== "" && obj.id === el.id
        );
        const ownElement = ownFiles.find(obj => obj.id === el.id);
        return (
          <ListGroup.Item
            key={idx}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <div>
              <label className="mr-2">{el.data().publicFileName}</label>
              <label>@{el.data().authorName}</label>
            </div>
            <ButtonToolbar>
              {ownElement ? (
                <Button
                  size="sm"
                  id="boton"
                  className="btn-danger mr-2"
                  onClick={() => {
                    //setDownloadHash(el.data().hash);
                    if (window.confirm("Do you really want delete this file?")) {
                      deleteFile(el);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              ) : null}
              {elementPass ? (
                <Button
                  size="sm"
                  id="boton"
                  className="btn-danger mr-2"
                  onClick={() => {
                    setDownloadHash(el.data().hash);
                    handleShowDisplayPassModal(elementPass);
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </Button>
              ) : null}
              <Button
                size="sm"
                id="boton"
                onClick={e => {
                  setDownloadHash(el.data().hash);
                  setDownloadType(el.data().type);
                  el.data().p
                    ? downloadFile(
                      storageRef,
                      el.data().hash,
                      el.data().type,
                      "",
                      downloadSucess,
                      setAlertInfo
                    )
                    : handleShowPassModal();
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </ButtonToolbar>
          </ListGroup.Item>
        );
      });
    }
    return <div>No hay elementos</div>;
  };

  const geo_error = err => {
    setAlertInfo({
      variant: "warning",
      message: `Error location: ${err.code} ${err.message}`,
      autoHide: true
    });
  };

  const geo_options = {
    enableHighAccuracy: true,
    maximumAge: 30000,
    timeout: 27000
  };
  // eslint-disable-next-line
  const getLocation = useCallback(() => {
    console.log("getLocation");
    navigator.geolocation.watchPosition(geo_success, geo_error, geo_options);
  });

  const showAlert = () => {
    if (alertInfo) {
      if (alertInfo.autoHide === true) {
        setTimeout(() => setAlertInfo(null), 3000);
      }
      return <Alert variant={alertInfo.variant}>{alertInfo.message}</Alert>;
    }
  };

  useLayoutEffect(() => {
    if (firstLoad) {
      setOwnFiles(readLocalStorageFiles());
      firebase
        .auth()
        .signInAnonymously()
        .catch(error => {
          // Handle Errors here.
          console.log(error);
          //var errorCode = error.code;
          //var errorMessage = error.message;
          // ...
        });
    }
  }, [firstLoad]);

  useEffect(() => {
    if (firstLoad) {
      getLocation();
      setFirstLoad(false);
    }
    if(addingFile){
      saveLocalStorage(ownFiles);
      setAddingFile(false);
    }
  }, [ownFiles, firstLoad, getLocation, addingFile]);

  return (
    <div>
      <header className="App-header">
        <Navbar bg="primary" variant="dark">
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              height="30"
              className="d-inline-block align-top"
            />
            {" Gloudi"}
          </Navbar.Brand>
        </Navbar>
        {showAlert()}
      </header>
      <Container fluid={true}>
        <main role="main">
          <div>
            <ListGroup variant="flush">{listFiles()}</ListGroup>
          </div>
          <div style={{ position: "fixed", bottom: 20, right: 20 }}>
            <Button
              className="btn-primary btn-circle btn-lg"
              id="boton"
              onClick={handleShowModal}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Button>
            <Button
              className="btn-danger btn-circle btn-lg"
              id="boton"
              onClick={handleShowSetings}
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
            <Button
              className="btn-info btn-circle btn-lg"
              id="boton"
              onClick={handleShowSetings}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </Button>
          </div>
        </main>
        <ShowPassModal
          show={showPassModal}
          onHide={handleCloseDisplayPassModal}
          pass={rememberPass}
        ></ShowPassModal>
        <DownloadModal
          show={showDownloadModal}
          onHide={handleCloseDownloadModal}
          link={downloadLik}
        ></DownloadModal>
        <Modal show={showPassModal} onHide={handleClosePassModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enter File Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                onChange={event => {
                  setDownloadPassword(event.target.value);
                }}
                value={downloadPassword}
                placeholder="File Passsword"
                aria-label="filePassword"
                aria-describedby="basic-addon1"
                type="password"
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setShowPassModal(false);
                downloadFile(
                  storageRef,
                  downloadHash,
                  downloadType,
                  downloadPassword,
                  downloadSucess,
                  setAlertInfo
                );
              }}
              variant="primary"
            >
              Download
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSettingsModal} onHide={handleCloseSettins}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="customRange1">Select Radio: {searchRadius}</label>
            <InputGroup className="mb-3">
              <FormControl
                onChange={event => {
                  setRadius(event.target.value);
                }}
                value={searchRadius}
                min="10"
                max="100"
                type="range"
                list="tickmarks"
                className="custom-range"
                id="customRange1"
              />
              <datalist id="tickmarks">
                <option value="0" label="10m" />
                <option value="10" />
                <option value="20" />
                <option value="30" />
                <option value="40" />
                <option value="50" label="50m" />
                <option value="60" />
                <option value="70" />
                <option value="80" />
                <option value="90" />
                <option value="100" label="100m" />
              </datalist>
            </InputGroup>
          </Modal.Body>
        </Modal>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                onChange={event => {
                  setAuthorName(event.target.value);
                }}
                value={authorName}
                placeholder="Author Name"
                aria-label="authorName"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                onChange={event => {
                  setPublicFileName(event.target.value);
                }}
                value={publicFileName}
                placeholder="Public File Name"
                aria-label="publicFileName"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                onChange={event => {
                  setFilePassword(event.target.value);
                }}
                value={filePassword}
                type="password"
                placeholder="Password"
                aria-label="password"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <div className="custom-file">
              <input
                onChange={handleSelectFile}
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
              ></input>
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                {fileSelected}
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleUploadFile}
              variant="primary"
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <footer className="App-footer" style={{ height: "80px" }}></footer>
    </div>
  );
}

export default App;
