const storageAvailable = type => {
  try {
    var storage = window[type],
      x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      storage.length !== 0
    );
  }
};
const readLocalStorageFiles = () => {
  if (storageAvailable("localStorage")) {
    const localData = localStorage.getItem("ownItems");
    const localFiles = localData ? JSON.parse(localData) : [];
    return localFiles;
  } else {
    return [];
  }
};
const saveLocalStorage = info => {
  const stringJson = JSON.stringify(info);
  if (storageAvailable("localStorage")) {
    localStorage.setItem("ownItems", stringJson);
  }
};

export { readLocalStorageFiles, saveLocalStorage };
