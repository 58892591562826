import React from "react";
import Modal from "react-bootstrap/Modal";

const ShowPassModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Body>
        <h3>{props.password}</h3>
      </Modal.Body>
    </Modal>
  );
}
export default ShowPassModal;