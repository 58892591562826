import * as CryptoJS from "crypto-js";

const downloadFile = (storageRef, hash, type, password, success, onError) => {
    const file = CryptoJS.AES.decrypt(hash, password).toString(
      CryptoJS.enc.Utf8
    );
    storageRef
      .child(`zipFiles/${file}`)
      .getDownloadURL()
      .then(url => {
        success(url, type);
      })
      .catch(error => {
        onError({
          variant: "danger",
          message: `Error location: ${error.code} ${error.message}`,
          autoHide: true
        });
      });
  };

 export {
    downloadFile
  }