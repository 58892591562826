const uploadFile = (
  storageRef,
  firebase,
  fileName,
  compresedFile,
  onSuccess,
  uploadProgress,
  uploadError
) => {
  var zipRef = storageRef.child("zipFiles");
  var metadata = {
    customMetadata: {
      'pass': 'miswebos'
    }
  };
  var uploadTask = zipRef.child(fileName).put(compresedFile, metadata);
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    snapshot => {
      let progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          uploadProgress({
            variant: "info",
            message: `Upload paused: ${progress} % done`
          });
          break;
        case firebase.storage.TaskState.RUNNING:
          uploadProgress({
            variant: "info",
            message: `Upload running: ${progress} % done`
          });
          break;
        default:
          break;
      }
    },
    error => {
      switch (error.code) {
        case "storage/unauthorized":
          uploadError({
            variant: "danger",
            message: `Error storage: ${error.code} ${error.message}`,
            autoHide: true
          });
          break;
        case "storage/canceled":
          uploadError({
            variant: "info",
            message: `Cancel upload: ${error.code} ${error.message}`,
            autoHide: true
          });
          break;
        case "storage/unknown":
          uploadError({
            variant: "error",
            message: `Error: ${error.code} ${error.message}`,
            autoHide: true
          });
          break;
        default:
          uploadError({
            variant: "error",
            message: `Error: ${error.code} ${error.message}`,
            autoHide: true
          });
          break;
      }
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        onSuccess(downloadURL);
      });
    }
  );
};

export { uploadFile };
