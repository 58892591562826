const getOperatingSistem = () => {
    let OSName = "unknownOs";
    if (navigator.appVersion.indexOf("Win")!==-1) OSName="Windows";
    if (navigator.appVersion.indexOf("Macintosh")!==-1) OSName="MacOS";
    if (navigator.appVersion.indexOf("X11")!==-1) OSName="UNIX";
    if (navigator.appVersion.indexOf("Linux")!==-1) OSName="Linux";
    if (navigator.appVersion.indexOf("iPad")!==-1) OSName="OSX";
    if (navigator.appVersion.indexOf("iPhone")!==-1) OSName="OSX";
    if (navigator.appVersion.indexOf("iPod")!==-1) OSName="OSX";
    return OSName;
}

export default getOperatingSistem;