import React from "react";
import Modal from "react-bootstrap/Modal";

const DownloadModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Body>
        <p>Displaying New Document\n\nUse Save As... to download, then click back to return to this page.</p>
        <h3><a href={props.link}>download</a></h3>
      </Modal.Body>
    </Modal>
  );
}
export default DownloadModal;
