const fbConfig = {
  apiKey: "AIzaSyCUyt8QSm8ebyYFP_HlFjWUYsBA6k9nU2s",
  authDomain: "sharing-419d1.firebaseapp.com",
  databaseURL: "https://sharing-419d1.firebaseio.com",
  projectId: "sharing-419d1",
  storageBucket: "sharing-419d1.appspot.com",
  messagingSenderId: "904899967572",
  appId: "1:904899967572:web:49b1b9792f5036da"
};

export default fbConfig;
